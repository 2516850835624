import React from "react";
import {Link} from "gatsby";
import PostTags from "../PostTags/PostTags";
import dateTime from "../../utils/dateTimeFormatter";

class PostListing extends React.Component {
    getPostList() {
        const postList = [];
        this.props.postEdges.forEach(postEdge => {
            postList.push({
                path: postEdge.node.fields.slug,
                tags: postEdge.node.frontmatter.tags,
                cover: postEdge.node.frontmatter.cover,
                title: postEdge.node.frontmatter.title,
                date: postEdge.node.fields.date,
                excerpt: postEdge.node.excerpt,
                timeToRead: postEdge.node.timeToRead
            });
        });
        return postList;
    }

    getImageUrl(fileName) {
        if(!!fileName && fileName.startsWith("http")){
            return fileName
        }
        const base = '/images/covers';
        let finalName = !!fileName ? fileName : '0.jpg'
        return `${base}/${finalName}`;
    }

    formatDate(date){
        return dateTime(date);
    }

    render() {
        const postList = this.getPostList();
        return (
            <section className="blog-listing container">
                {/* Your post list here. */
                    postList.map(post => (
                        <article className="row" key={post.title}>
                            <div className="col-4 cover">
                                <img src={this.getImageUrl(post.cover)} alt={post.title}/>
                            </div>
                            <div className="col-7 content">
                                <PostTags tags={post.tags} />
                                <Link to={post.path}>
                                    <h1>{post.title}</h1>
                                </Link>
                                <p className="excerpt">{post.excerpt}</p>
                                <p className="note"><small>Published <i>{this.formatDate(post.date)}</i></small> | <small>Time to read: <i>{post.timeToRead} minutes</i></small></p>

                            </div>
                        </article>
                    ))
                }
            </section>
        );
    }
}

export default PostListing;
